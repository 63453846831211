<template>
  <div class="trait-wrap">
    <div class="pc-show">
      <div id="infos-table" class="infos-table">
        <div class="title">
          <img src="~@/assets/Images/result_logo.png" />
          <div>DISC人格特質測評報告 <span style="margin-left: 15px"></span></div>
        </div>

        <div class="row">
          <div class="col">
            <div class="section-block">
              <div class="text-center" style="font-size: 1.25em">
                受測者
                <span
                  style="font-size: 1.3em; font-weight: bold; color: blue; margin: 0 10px"
                  >{{ patternModal.Name }}</span
                >
                <span v-if="patternModal.Sex === 1">先生</span>
                <span v-if="patternModal.Sex === 2">小姐</span>
              </div>

              <div class="info-block">
                  <div class="text-center">測驗基本資料</div>
                  日期：{{ patternModal.FinishTime | dateFormat }}<br />
                  單位：{{ patternModal.DepartmentName }}<br />
                  年齡：{{ patternModal.BirthDate | dateFormat }}({{
                    patternModal.Age
                  }}歲)<br />
                  年資：{{ patternModal.GroupDate | dateFormat }}({{
                    patternModal.WorkAge
                  }}年)
              </div>
            </div>
          </div>

          <div class="col">
            <div class="section-block">
              <div class="text-center" style="font-size: 1.25em">測驗結果顯示您為</div>
              <div class="ql-editor">
                <div class="desc" v-html="patternModal.Description"></div>
              </div>
              <img
                style="max-width: 100%; display: block; margin: 0 auto"
                v-if="patternModal.AnimalPicSrc"
                :src="patternModal.AnimalPicSrc"
                alt="人格特質圖"
              />
            </div>
          </div>
          <div class="col">
            <div class="section-block">
              <div class="flex">
                <img src="~@/assets/Images/icon_01.png" />
                <div class="ql-editor">
                  <div v-html="patternModal.AdvantageDescr"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />

        <div class="title">DISC 測驗結果說明</div>
        <div class="row">
          <div class="col-6">
            <div class="chart-block">
              <div class="text-center" style="font-size: 1.25em">特質說明</div>
              <div class="ql-editor" style="padding: 0">
                <div v-html="patternModal.TraitDescr"></div>
              </div>
            </div>
          </div>

          <div class="col-6">
            <table class="desc-table">
              <tr>
                <td>
                  <div class="img-wrap" style="font-size: 1.25em">
                    <img src="~@/assets/Images/icon_04.jpg" />
                    溝通方式
                  </div>
                </td>
                <td>
                  <div class="ql-editor" v-html="patternModal.CommsWayDescr"></div>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div style="font-size: 1.25em; text-align: center">DISC 測驗結果比例</div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="bar-list">
              <div class="bar">
                <div
                  class="line"
                  :style="'width:' + patternModal.DominancePercent + '%'"
                ></div>
                <span>{{ patternModal.DominancePercent }}%</span>
                <span class="an-name">老虎型特質</span>
              </div>
              <div class="bar">
                <div
                  class="line"
                  :style="'width:' + patternModal.InfluencePercent + '%'"
                ></div>
                <span>{{ patternModal.InfluencePercent }}%</span>
                <span class="an-name">孔雀型特質</span>
              </div>
              <div class="bar">
                <div
                  class="line"
                  :style="'width:' + patternModal.SteadinessPercent + '%'"
                ></div>
                <span>{{ patternModal.SteadinessPercent }}%</span>
                <span class="an-name">無尾熊型特質</span>
              </div>
              <div class="bar">
                <div
                  class="line"
                  :style="'width:' + patternModal.CompliancePercent + '%'"
                ></div>
                <span>{{ patternModal.CompliancePercent }}%</span>
                <span class="an-name">貓頭鷹型特質</span>
              </div>
            </div>
          </div>
          <div class="col-6">
            <table class="desc-table" style="background-color: #f5f5f5">
              <tr>
                <td>
                  <div class="img-wrap" style="font-size: 1.25em">
                    <img src="~@/assets/Images/icon_05.jpg" />
                    工作提醒
                  </div>
                </td>
                <td>
                  <div class="ql-editor" v-html="patternModal.AdviceDescr"></div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="text-center">
        <el-button type="primary" class="print-btn" round @click="chartVisible = true">
          <!-- <img
          style="height: 30px"
          src="~@/assets/Images/icon_06.jpg"
        /> -->
          點此看DISC人格特質應用</el-button
        >
        <el-button type="primary" class="print-btn" round v-print="'#infos-table'"
          >列印</el-button
        >
      </div>
      <el-dialog
        title="DISC人格特質分析"
        :visible.sync="chartVisible"
        width="60%"
        top="40px"
        append-to-body
      >
        <img
          style="max-width: 100%; display: block; margin: 0 auto"
          v-if="patternModal.ChartsPicSrc"
          :src="patternModal.ChartsPicSrc"
          alt="人格特質圖"
        />
        <div class="no-pic" v-else>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
            <path
              d="M152 120c-26.51 0-48 21.49-48 48s21.49 48 48 48s48-21.49 48-48S178.5 120 152 120zM447.1 32h-384C28.65 32-.0091 60.65-.0091 96v320c0 35.35 28.65 64 63.1 64h384c35.35 0 64-28.65 64-64V96C511.1 60.65 483.3 32 447.1 32zM463.1 409.3l-136.8-185.9C323.8 218.8 318.1 216 312 216c-6.113 0-11.82 2.768-15.21 7.379l-106.6 144.1l-37.09-46.1c-3.441-4.279-8.934-6.809-14.77-6.809c-5.842 0-11.33 2.529-14.78 6.809l-75.52 93.81c0-.0293 0 .0293 0 0L47.99 96c0-8.822 7.178-16 16-16h384c8.822 0 16 7.178 16 16V409.3z"
            /></svg
          ><br />
          暫無對應特質圖
        </div>

        <span slot="footer" class="dialog-footer">
          <el-button @click="chartVisible = false">關閉</el-button>
        </span>
      </el-dialog>
    </div>

    <div class="mobile-show">
      <div class="infos-table">
        <div class="title">
          <img src="~@/assets/Images/result_logo.png" />
          <div>DISC人格特質測評報告</div>
        </div>

        <div class="row">
          <div class="col-md-4 col-sm-12">
            <div class="section-block">
              <div class="text-center s-title">
                受測者
                <span
                  style="font-size: 1.3em; font-weight: bold; color: blue; margin: 0 10px"
                  >{{ patternModal.Name }}</span
                >
                <span v-if="patternModal.Sex === 1">先生</span>
                <span v-if="patternModal.Sex === 2">小姐</span>
              </div>

              <div class="info-block">
                <div class="info-block-inner">
                <div class="text-center">測驗基本資料</div>
                日期：{{ patternModal.FinishTime | dateFormat }}<br />
                單位：{{ patternModal.DepartmentName }}<br />
                年齡：{{ patternModal.BirthDate | dateFormat }}({{
                  patternModal.Age
                }}歲)<br />
                年資：{{ patternModal.GroupDate | dateFormat }}({{
                  patternModal.WorkAge
                }}年)
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-4 col-sm-12">
            <div class="section-block">
              <div class="text-center s-title">測驗結果顯示您為</div>
              <div class="ql-editor">
                <div class="desc" v-html="patternModal.Description"></div>
              </div>
              <img
                style="max-width: 100%; display: block; margin: 0 auto"
                v-if="patternModal.AnimalPicSrc"
                :src="patternModal.AnimalPicSrc"
                alt="人格特質圖"
              />
            </div>
          </div>
          <div class="col-md-4 col-sm-12">
            <div class="section-block">
              <div class="flex">
                <img src="~@/assets/Images/icon_01.png" />
                <div class="ql-editor">
                  <div v-html="patternModal.AdvantageDescr"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />

        <div class="title">DISC 測驗結果說明</div>
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <div class="chart-block">
              <div class="text-center s-title">特質說明</div>
              <div class="ql-editor" style="padding: 0">
                <div v-html="patternModal.TraitDescr"></div>
              </div>
            </div>

            <div class="text-center s-title">DISC 測驗結果比例</div>

            <div class="bar-list">
              <div class="bar">
                <div
                  class="line"
                  :style="'width:' + patternModal.DominancePercent + '%'"
                ></div>
                <span>{{ patternModal.DominancePercent }}%</span>
                <span class="an-name">老虎型特質</span>
              </div>
              <div class="bar">
                <div
                  class="line"
                  :style="'width:' + patternModal.InfluencePercent + '%'"
                ></div>
                <span>{{ patternModal.InfluencePercent }}%</span>
                <span class="an-name">孔雀型特質</span>
              </div>
              <div class="bar">
                <div
                  class="line"
                  :style="'width:' + patternModal.SteadinessPercent + '%'"
                ></div>
                <span>{{ patternModal.SteadinessPercent }}%</span>
                <span class="an-name">無尾熊型特質</span>
              </div>
              <div class="bar">
                <div
                  class="line"
                  :style="'width:' + patternModal.CompliancePercent + '%'"
                ></div>
                <span>{{ patternModal.CompliancePercent }}%</span>
                <span class="an-name">貓頭鷹型特質</span>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-sm-12">
            <table class="desc-table">
              <tr>
                <td>
                  <div class="img-wrap" style="font-size: 1.25em">
                    <img src="~@/assets/Images/icon_04.jpg" />
                    溝通方式
                  </div>
                </td>
                <td>
                  <div class="ql-editor" v-html="patternModal.CommsWayDescr"></div>
                </td>
              </tr>
            </table>

            <table class="desc-table" style="background-color: #f5f5f5">
              <tr>
                <td>
                  <div class="img-wrap" style="font-size: 1.25em">
                    <img src="~@/assets/Images/icon_05.jpg" />
                    工作提醒
                  </div>
                </td>
                <td>
                  <div class="ql-editor" v-html="patternModal.AdviceDescr"></div>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div
          class="text-center"
          style="color: #dd8400; font-size: 1.5em; font-weight: bold"
        >
          「如需列印請至電腦或平板操作」
        </div>

        <div class="title">DISC人格特質應用說明</div>
        <div class="ql-editor" style="padding: 0">
          <div v-html="patternModal.DiscDescription"></div>
        </div>

        <img
          style="max-width: 100%; display: block; margin: 20px auto"
          v-if="patternModal.ChartPicMobileSrc"
          :src="patternModal.ChartPicMobileSrc"
          alt="人格特質圖"
        />
        <div class="no-pic" v-else>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
            <path
              d="M152 120c-26.51 0-48 21.49-48 48s21.49 48 48 48s48-21.49 48-48S178.5 120 152 120zM447.1 32h-384C28.65 32-.0091 60.65-.0091 96v320c0 35.35 28.65 64 63.1 64h384c35.35 0 64-28.65 64-64V96C511.1 60.65 483.3 32 447.1 32zM463.1 409.3l-136.8-185.9C323.8 218.8 318.1 216 312 216c-6.113 0-11.82 2.768-15.21 7.379l-106.6 144.1l-37.09-46.1c-3.441-4.279-8.934-6.809-14.77-6.809c-5.842 0-11.33 2.529-14.78 6.809l-75.52 93.81c0-.0293 0 .0293 0 0L47.99 96c0-8.822 7.178-16 16-16h384c8.822 0 16 7.178 16 16V409.3z"
            /></svg
          ><br />
          暫無對應特質圖
        </div>

        <div v-if="patternModal.Check_Tiger" class="d-wrap animal-wrap fill">
          <div class="animal-title">積極力/老虎型</div>
          <div class="ql-editor" style="padding: 0">
            <div v-html="patternModal.Desc_Tiger"></div>
          </div>
        </div>

        <div v-if="patternModal.Check_Peacock" class="i-wrap animal-wrap fill">
          <div class="animal-title">影響力/孔雀型</div>
          <div class="ql-editor" style="padding: 0">
            <div v-html="patternModal.Desc_Peacock"></div>
          </div>
        </div>

        <div v-if="patternModal.Check_Koala" class="s-wrap animal-wrap fill">
          <div class="animal-title">穩健力/無尾熊型</div>
          <div class="ql-editor" style="padding: 0">
            <div v-html="patternModal.Desc_Koala"></div>
          </div>
        </div>

        <div v-if="patternModal.Check_Owl" class="c-wrap animal-wrap fill">
          <div class="animal-title">邏輯力/貓頭鷹型</div>
          <div class="ql-editor" style="padding: 0">
            <div v-html="patternModal.Desc_Owl"></div>
          </div>
        </div>

        <div v-if="!patternModal.Check_Tiger" class="d-wrap animal-wrap">
          <div class="animal-title">積極力/老虎型</div>
          <div class="ql-editor" style="padding: 0">
            <div v-html="patternModal.Desc_Tiger"></div>
          </div>
        </div>

        <div v-if="!patternModal.Check_Peacock" class="i-wrap animal-wrap">
          <div class="animal-title">影響力/孔雀型</div>
          <div class="ql-editor" style="padding: 0">
            <div v-html="patternModal.Desc_Peacock"></div>
          </div>
        </div>

        <div v-if="!patternModal.Check_Koala" class="s-wrap animal-wrap">
          <div class="animal-title">穩健力/無尾熊型</div>
          <div class="ql-editor" style="padding: 0">
            <div v-html="patternModal.Desc_Koala"></div>
          </div>
        </div>

        <div v-if="!patternModal.Check_Owl" class="c-wrap animal-wrap">
          <div class="animal-title">邏輯力/貓頭鷹型</div>
          <div class="ql-editor" style="padding: 0">
            <div v-html="patternModal.Desc_Owl"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import print from "vue-print-nb";
export default {
  directives: {
    print,
  },
  props: ["patternModal"],
  data() {
    return {
      chartVisible: false,
    };
  },

  methods: {},
  created() {},
};
</script>

<style lang="scss" scoped>
.infos-table {
  font-size: 16px;
  background-color: #fff;
  font-weight: bold;

  padding: 10px;
  color: #000;
  margin-bottom: 10px;

  .section-block {
    padding: 0;
  }
  .info-block {
    background-color: #f1f1f1;
    font-size: 1.25em;
    padding: 10px;
    line-height: 185%;
  }
  .ql-editor {
    height: auto;
  }

  @media print {
    print-color-adjust: exact;
  }
  .s-title {
    font-size: 1.25em;
  }

  .title {
    border-bottom: 1px solid #ccc;
    padding: 10px 60px;
    font-size: 1.25em;
    margin-bottom: 20px;
    text-align: center;
    img {
      height: 60px;
      display: inline-block;
      vertical-align: middle;
    }

    div {
      font-size: 2.25em;
      font-weight: bold;
      display: inline-block;
      vertical-align: middle;

      @media (max-width: 767px) {
        font-size: 1.4em;
      }
    }
  }
  .flex {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .desc {
    // font-size: 18px;
    text-align: center;
  }

  .desc-table {
    width: 100%;
    margin-bottom: 20px;
    text-align: left;
    height: 230px;
    tr {
      td {
        vertical-align: middle;

        .img-wrap {
          width: 85px;
          text-align: center;
          img {
            width: 65px;
          }
        }
      }
    }
  }

  .no-pic {
    background-color: #fef9f3;
    padding: 20px;
    text-align: center;

    svg {
      width: 100px;
      margin-bottom: 20px;
      opacity: 0.4;
    }
  }

  .bar-list {
    .bar {
      background-color: #eee6e3;
      margin-bottom: 17px;
      text-align: right;
      position: relative;
      border-radius: 25px;
      overflow: hidden;
      @media print {
        print-color-adjust: exact;
      }

      .an-name {
        width: 110px;
        display: inline-block;
        text-align: left;
      }

      .line {
        position: absolute;
        border-radius: 25px;
        left: 0;
        height: 100%;
        z-index: 1;
      }

      span {
        z-index: 2;
        position: relative;
        margin-right: 10px;
        line-height: 35px;
      }

      &:nth-child(1) {
        .line {
          background-color: #fe0000 !important;
        }
      }
      &:nth-child(2) {
        .line {
          background-color: #fec001 !important;
        }
      }
      &:nth-child(3) {
        .line {
          background-color: #01b051 !important;
        }
      }
      &:nth-child(4) {
        .line {
          background-color: #204e7a !important;
        }
      }
    }
  }
}

.animal-wrap {
  margin: 0 auto;
  width: 90%;
  border-radius: 5px;
  margin-top: 20px;
  padding: 10px;
  background-color: #e9e9e9;

  .animal-title {
    color: #000;
    text-align: center;
    font-size: 1.35em;
    font-weight: bold;
    margin-bottom: 10px;
  }

  &.d-wrap {
    border: 5px solid #fe0000;

    &.fill {
      background-color: rgba(#fe0000, 0.1);
    }
  }
  &.i-wrap {
    border: 5px solid #fcc632;

    &.fill {
      background-color: rgba(#fcc632, 0.1);
    }
  }
  &.s-wrap {
    border: 5px solid #03c24c;

    &.fill {
      background-color: rgba(#03c24c, 0.1);
    }
  }
  &.c-wrap {
    border: 5px solid #3388f7;

    &.fill {
      background-color: rgba(#3388f7, 0.1);
    }
  }
}

@media (min-width: 768px) {
  .mobile-show {
    display: none;
  }
}
@media (max-width: 767px) {
  .pc-show {
    display: none;
  }
  .infos-table {
    font-size: 14px;
  }

  .info-block{
    text-align: center;
    .info-block-inner{
      text-align: left;
      display: inline-block;
    }
  }

  .s-title {
    margin-top: 10px;
  }
}

@media print {
  * {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}
</style>
